function Sidebar() {
    return ( 
        <>
            <div id="sidebar">
                <div id="toggleClose">
                    <span class="lnr lnr-cross"></span>
                </div>
                <div id="menu">
                    <div id="photo-container">
                        <div id="profile-photo"></div>
                    </div>
                    <nav>
                        <a id="nav-item-1" href="#hero">
                            <div>Home</div>
                        </a>
                        <a id="nav-item-2" href="#education">
                            <div>Education</div>
                        </a>
                        <a id="nav-item-3" href="#experience">
                            <div>Experience</div>
                        </a>
                        <a id="linkedin" href="https://www.linkedin.com/in/noahbcaldwell/">
                            <div>LinkedIn</div>
                        </a>
                        <a id="nav-item-5" href="https://techtalksbynoah.com/">
                            <div>Blog</div>
                        </a>
                    </nav>
                </div>
            </div>
        </>
    );
}

export default Sidebar;