import Header from './components/Header'
import Sidebar from './components/Sidebar'
import Hero from './components/Hero'
import Education from './components/Education'
import Experience from './components/Experience'
import Footer from './components/Footer'


function App() {
  return (
    <>
      <Header />
      <Sidebar />
      <Hero />
      <Education />
      <Experience />
      <Footer />
    </>
  );
}

export default App;
