function Header() {
    return ( 
        <>
            <header id="sticky-header">
                <div id="toggleOpen">
                    <span class="lnr lnr-menu"></span>
                </div>
            </header>
        </>
     );
}

export default Header;