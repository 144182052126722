function Experience() {
    return ( 
        <>
            <div id="experience" class="contentTitle">EXPERIENCE</div>
            <div class="content">
                <div class="item">
                    <div class="itemTitle">
                        <p>APSU | 2023</p>
                    </div>
                    <div class="itemDescription">
                        <p>
                            Leads discussion sessions, tutorials, or lab sections to reinforce classroom concepts and engage students in interactive learning.
                            Offers guidance on course content, clarify doubts, and provide academic support to students during office hours.
                            Assists in grading assignments, quizzes, exams, and other assessments according to established rubrics and guidelines.
                            Provides constructive feedback to students to aid in their academic progress.
                            Contributes ideas and suggestions for improving course materials, curriculum design, and instructional methods.
                            Collaborates with faculty to develop new teaching strategies that align with educational goals.
                        </p>    
                    </div>
                </div>
                <div class="item">
                    <div class="itemTitle">
                        <p>The Fostered Gift | 2022 - Present</p>
                    </div>
                    <div class="itemDescription">
                        <p>
                            Regularly updates WordPress core, themes, and plugins to maintain website security and functionality.
                            Tests and troubleshoots website compatibility with various browsers, devices, and operating systems.
                            Addresses and resolves any website-related issues, bugs, or glitches promptly.
                            Monitors website performance, load times, and overall responsiveness.
                            Implements performance optimization techniques such as caching, image optimization, and content delivery network (CDN) integration.
                            Implements and maintains robust security measures to protect the website from unauthorized access, hacking, and data breaches.
                            Regularly backs up website data and files to prevent data loss.
                        </p>    
                    </div>
                </div>
                <div class="item">
                    <div class="itemTitle">
                        <p>ARH Inspirations | 2018 - Present</p>
                    </div>
                    <div class="itemDescription">
                        <p>
                            Regularly updates WordPress core, themes, and plugins to maintain website security and functionality.
                            Tests and troubleshoots website compatibility with various browsers, devices, and operating systems.
                            Addresses and resolves any website-related issues, bugs, or glitches promptly.
                            Monitors website performance, load times, and overall responsiveness.
                            Implements performance optimization techniques such as caching, image optimization, and content delivery network (CDN) integration.
                            Implements and maintains robust security measures to protect the website from unauthorized access, hacking, and data breaches.
                            Regularly backs up website data and files to prevent data loss.
                        </p>    
                    </div>
                </div>
                <div class="item">
                    <div class="itemTitle">
                        <p>STEMC | 2020</p>
                    </div>
                    <div class="itemDescription">
                        <p>
                            Assisted in monitoring security alerts and analyzing potential threats to identify and respond to security incidents.
                            Collaborated with the security team to investigate and assess the severity of incidents.
                            Supported vulnerability assessment activities, including scanning systems for potential vulnerabilities and assisting with the remediation process.
                            Gained hands-on experience with various security tools and technologies used for monitoring, detection, and prevention.
                            Analyzed security logs and events to identify patterns or anomalies that might indicate security breaches.
                        </p>    
                    </div>
                </div>
                <div class="item">
                    <div class="itemTitle">
                        <p>DSCC | 2019 - 2020</p>
                    </div>
                    <div class="itemDescription">
                        <p>
                            Responded to incoming support requests via various channels, including phone, email, and ticketing system.
                            Provided prompt and courteous assistance to users experiencing technical issues, answering inquiries, and troubleshooting problems.
                            Analyzed and diagnose technical problems related to hardware, software, applications, and network connectivity.
                            Resolved issues by guiding users through appropriate steps or by remote troubleshooting.
                            Created, updated, and managed support tickets using the helpdesk ticketing system.
                            Ensured accurate and detailed documentation of user interactions, troubleshooting steps, and issue resolutions.
                            Escalated complex issues to senior technical staff or relevant teams while maintaining communication with users.
                            Collaborated with other IT team members to identify and address recurring or widespread technical problems.
                        </p>
                    </div>
                </div>
                <div class="item">
                    <div class="itemTitle">
                        <p>Holly Grove Church | 2018 - 2022</p>
                    </div>
                    <div class="itemDescription">
                        <p>
                            Regularly updated WordPress core, themes, and plugins to maintain website security and functionality.
                            Tested and troubleshooted website compatibility with various browsers, devices, and operating systems.
                            Addressed and resolved any website-related issues, bugs, or glitches promptly.
                            Monitored website performance, load times, and overall responsiveness.
                            Implemented performance optimization techniques such as caching, image optimization, and content delivery network (CDN) integration.
                            Implemented and maintained robust security measures to protect the website from unauthorized access, hacking, and data breaches.
                            Regularly backed up website data and files to prevent data loss.
                        </p>
                    </div>
                </div>
                <div class="item">
                    <div class="itemTitle">
                        <p>Kiewit | 2018</p>
                    </div>
                    <div class="itemDescription">
                        <p>
                            Assisted in configuring and setting up network devices, including routers, switches, and firewalls.
                            Learned about IP addressing, subnetting, and network segmentation.
                            Participated in monitoring network performance and diagnosing connectivity and performance issues.
                            Collaborated with the network team to troubleshoot and resolve network-related incidents.
                            Assisted in documenting network topology, configurations, and changes.
                            Contributed to maintaining accurate and up-to-date network documentation.
                        </p>
                    </div>
                </div>
            </div>
        </>
     );
}

export default Experience;
