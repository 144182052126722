function Education() {
    return ( 
        <>
            <div id="education" class="contentTitle">EDUCATION</div>
            <div class="content">
                <div class="item">
                    <div class="itemTitle">
                        <p>APSU | 2023</p>
                    </div>
                    <div class="itemDescription">
                        <p>Following graduation with my bachelor’s degree, I enrolled in APSU's Computer Science and Quantitative Methods (CSQM M.S.) graduate degree program with dual concentrations in Information Assurance and Security and Mathematical Finance. By the time of my expected graduation in Fall of 2023, I will also have obtained a certificate in risk analysis.</p>
                    </div>
                </div>
                <div class="item">
                    <div class="itemTitle">
                        <p>APSU | 2022</p>
                    </div>
                    <div class="itemDescription">
                        <p>In May 2022, I graduated Magna Cum Laude with my Bachelor of Science in Computer Information Systems, Information Assurance and Security concentration with a networking minor. I gained skills in computer networking, network security, virtualization, windows server, risk management, cryptographic systems, and digital forensics.</p>
                    </div>
                </div>
                <div class="item">
                    <div class="itemTitle">
                        <p>DSCC | 2020</p>
                    </div>
                    <div class="itemDescription">
                        <p>After high school, I continued my education at Dyersburg State Community College by completing my Associates of Applied Science in Computer Information Technology with a concentration in Computer Networking. I graduated with honors (Summa Cum Laude) and also actively participated in the Phi Theta Kappa honors society and Student Government Association.</p>
                    </div>
                </div>
            </div>
        </>
     );
}

export default Education ;