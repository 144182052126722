function Hero() {
    return ( 
        <>
            <section id="hero">
                <div id="hero-content">
                    <h2 id="hero-title">Noah Caldwell</h2>
                    <h3 id="hero-subtitle">InfoSec and Finance Student</h3>
                </div>
            </section>
        </>
     );
}

export default Hero;