function Footer() {
    return ( 
        <>
            <footer id="footer">
                <p>© Copyright <span id="year"></span> | Noah Caldwell</p>
            </footer>
        </>
     );
}

export default Footer;